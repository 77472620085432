import React from 'react';
import { PageContentLayout } from '@/finsight/ui/common/components/ContentLayouts';
import PageNotFound from '@/finsight/ui/common/components/PageNotFoundWrp';

const CompanyPage = () => (
  <PageContentLayout>
    <PageNotFound />
  </PageContentLayout>
);

export default CompanyPage;
